var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    [
      _c("AConfirmation", {
        attrs: { isShow: _vm.isPending },
        on: { cancel: _vm.cancelSubmit, confirm: _vm.submit },
      }),
      _c("ASuccessFour", { attrs: { api: _vm.api } }),
      _vm.api.isLoading
        ? _c("v-skeleton-loader", { ref: "skeleton", attrs: { type: "table" } })
        : _c(
            "v-row",
            [
              _c(
                "v-col",
                { staticClass: "mx-auto", attrs: { cols: "12", sm: "6" } },
                [
                  _c(
                    "div",
                    { staticClass: "d-flex justify-space-between" },
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass: "mb-3",
                          attrs: { color: "primary" },
                          on: { click: _vm.closeDetail },
                        },
                        [_c("v-icon", [_vm._v(" mdi-arrow-left-bold ")])],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-card",
                    { staticClass: "pa-3" },
                    [
                      _c(
                        "v-toolbar",
                        { staticClass: "secondary white--text " },
                        [
                          _c(
                            "v-toolbar-title",
                            { staticClass: "font-weigh-bold text-h5" },
                            [_vm._v(" Claim Reward ")]
                          ),
                        ],
                        1
                      ),
                      _vm.rewards != null
                        ? _c(
                            "div",
                            { staticClass: "mt-3 pt-4 pb-0" },
                            [
                              _c(
                                "div",
                                { staticClass: "d-flex justify-start" },
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      dense: "",
                                      disabled: "",
                                      outlined: "",
                                      label: "User",
                                    },
                                    model: {
                                      value: _vm.rewards.username,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.rewards, "username", $$v)
                                      },
                                      expression: "rewards.username",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "v-row",
                                [
                                  _c(
                                    "v-col",
                                    [
                                      _c("v-text-field", {
                                        attrs: {
                                          dense: "",
                                          disabled: "",
                                          outlined: "",
                                          label: "Email",
                                        },
                                        model: {
                                          value: _vm.rewards.email,
                                          callback: function ($$v) {
                                            _vm.$set(_vm.rewards, "email", $$v)
                                          },
                                          expression: "rewards.email",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-col",
                                    [
                                      _c("v-text-field", {
                                        attrs: {
                                          dense: "",
                                          disabled: "",
                                          outlined: "",
                                          label: "Mobile",
                                        },
                                        model: {
                                          value: _vm.rewards.mobile,
                                          callback: function ($$v) {
                                            _vm.$set(_vm.rewards, "mobile", $$v)
                                          },
                                          expression: "rewards.mobile",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                { staticClass: "d-flex justify-start" },
                                [
                                  _c("v-textarea", {
                                    attrs: {
                                      label: "Address",
                                      outlined: "",
                                      disabled: "",
                                      dense: "",
                                    },
                                    model: {
                                      value: _vm.rewards.address,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.rewards, "address", $$v)
                                      },
                                      expression: "rewards.address",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                { staticClass: "d-flex justify-start" },
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      dense: "",
                                      disabled: "",
                                      label: "Reward",
                                      outlined: "",
                                    },
                                    model: {
                                      value: _vm.rewards.name,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.rewards, "name", $$v)
                                      },
                                      expression: "rewards.name",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                {},
                                [
                                  _c("v-select", {
                                    staticClass: "mb-0 pb-0",
                                    staticStyle: {
                                      "margin-bottom": "0px",
                                      "padding-bottom": "0px",
                                    },
                                    attrs: {
                                      items: _vm.statusOptions,
                                      dense: "",
                                      "item-text": "text",
                                      "item-value": "value",
                                      label: "Status",
                                      outlined: "",
                                    },
                                    model: {
                                      value: _vm.rewards.status,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.rewards, "status", $$v)
                                      },
                                      expression: "rewards.status",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                { staticClass: "d-flex justify-start" },
                                [
                                  _c("v-checkbox", {
                                    attrs: {
                                      dense: "",
                                      outlined: "",
                                      label: "Send email",
                                    },
                                    model: {
                                      value: _vm.isSendingEmail,
                                      callback: function ($$v) {
                                        _vm.isSendingEmail = $$v
                                      },
                                      expression: "isSendingEmail",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _vm.isSendingEmail
                                ? _c(
                                    "div",
                                    { staticClass: "d-flex justify-start" },
                                    [
                                      _c("v-autocomplete", {
                                        attrs: {
                                          items: _vm.rewardSendOptions,
                                          "item-text": "text",
                                          "item-value": "value",
                                          dense: "",
                                          outlined: "",
                                          label: "Email Template",
                                        },
                                        model: {
                                          value: _vm.rewardsSend,
                                          callback: function ($$v) {
                                            _vm.rewardsSend = $$v
                                          },
                                          expression: "rewardsSend",
                                        },
                                      }),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _c(
                                "v-row",
                                [
                                  ["evoucher", "courier"].includes(
                                    _vm.rewardsSend
                                  ) && _vm.isSendingEmail
                                    ? _c(
                                        "v-col",
                                        {},
                                        [
                                          _c(
                                            "v-row",
                                            [
                                              _c(
                                                "v-col",
                                                [
                                                  _c("v-text-field", {
                                                    attrs: {
                                                      dense: "",
                                                      outlined: "",
                                                      label: "Reward name",
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.rewardEmailTemplate
                                                          .rewardName,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.rewardEmailTemplate,
                                                          "rewardName",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "rewardEmailTemplate.rewardName",
                                                    },
                                                  }),
                                                  _c("v-text-field", {
                                                    attrs: {
                                                      dense: "",
                                                      outlined: "",
                                                      label: "Recipient name",
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.rewardEmailTemplate
                                                          .clientName,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.rewardEmailTemplate,
                                                          "clientName",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "rewardEmailTemplate.clientName",
                                                    },
                                                  }),
                                                  _c("v-text-field", {
                                                    attrs: {
                                                      dense: "",
                                                      outlined: "",
                                                      label: "Recipient email",
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.rewardEmailTemplate
                                                          .clientEmail,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.rewardEmailTemplate,
                                                          "clientEmail",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "rewardEmailTemplate.clientEmail",
                                                    },
                                                  }),
                                                  _vm.rewardsSend === "evoucher"
                                                    ? _c("v-file-input", {
                                                        attrs: {
                                                          accept:
                                                            "image/png, image/jpeg",
                                                          color: "primary",
                                                          dense: "",
                                                          counter: "",
                                                          "show-size": 1000,
                                                          outlined: "",
                                                          "prepend-icon": "",
                                                          label:
                                                            "Please upload the coupon image",
                                                        },
                                                        model: {
                                                          value:
                                                            _vm
                                                              .rewardEmailTemplate
                                                              .attachment,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.rewardEmailTemplate,
                                                              "attachment",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "rewardEmailTemplate.attachment",
                                                        },
                                                      })
                                                    : _vm._e(),
                                                  _vm.rewardsSend === "courier"
                                                    ? _c(
                                                        "div",
                                                        [
                                                          _c("v-text-field", {
                                                            attrs: {
                                                              dense: "",
                                                              outlined: "",
                                                              label:
                                                                "Courier service",
                                                            },
                                                            model: {
                                                              value:
                                                                _vm
                                                                  .rewardEmailTemplate
                                                                  .courierService,
                                                              callback:
                                                                function ($$v) {
                                                                  _vm.$set(
                                                                    _vm.rewardEmailTemplate,
                                                                    "courierService",
                                                                    $$v
                                                                  )
                                                                },
                                                              expression:
                                                                "rewardEmailTemplate.courierService",
                                                            },
                                                          }),
                                                          _c("v-text-field", {
                                                            attrs: {
                                                              dense: "",
                                                              outlined: "",
                                                              label:
                                                                "Tracking number",
                                                            },
                                                            model: {
                                                              value:
                                                                _vm
                                                                  .rewardEmailTemplate
                                                                  .trackingNumber,
                                                              callback:
                                                                function ($$v) {
                                                                  _vm.$set(
                                                                    _vm.rewardEmailTemplate,
                                                                    "trackingNumber",
                                                                    $$v
                                                                  )
                                                                },
                                                              expression:
                                                                "rewardEmailTemplate.trackingNumber",
                                                            },
                                                          }),
                                                          _c("v-textarea", {
                                                            attrs: {
                                                              label:
                                                                "Recipient's address",
                                                              outlined: "",
                                                              dense: "",
                                                            },
                                                            model: {
                                                              value:
                                                                _vm
                                                                  .rewardEmailTemplate
                                                                  .clientAddress,
                                                              callback:
                                                                function ($$v) {
                                                                  _vm.$set(
                                                                    _vm.rewardEmailTemplate,
                                                                    "clientAddress",
                                                                    $$v
                                                                  )
                                                                },
                                                              expression:
                                                                "rewardEmailTemplate.clientAddress",
                                                            },
                                                          }),
                                                        ],
                                                        1
                                                      )
                                                    : _vm._e(),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                              _c(
                                "v-row",
                                [
                                  _c(
                                    "v-col",
                                    [
                                      _c("v-text-field", {
                                        attrs: {
                                          label: "Points Required",
                                          dense: "",
                                          disabled: "",
                                          outlined: "",
                                        },
                                        model: {
                                          value: _vm.rewards.required_points,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.rewards,
                                              "required_points",
                                              $$v
                                            )
                                          },
                                          expression: "rewards.required_points",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-col",
                                    [
                                      _c("v-text-field", {
                                        attrs: {
                                          dense: "",
                                          disabled: "",
                                          outlined: "",
                                          label: "Quantity",
                                        },
                                        model: {
                                          value: _vm.rewards.reward_quantity,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.rewards,
                                              "reward_quantity",
                                              $$v
                                            )
                                          },
                                          expression: "rewards.reward_quantity",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                { staticClass: "d-flex justify-content-start" },
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      label: "Total Points Deducted",
                                      dense: "",
                                      disabled: "",
                                      outlined: "",
                                    },
                                    model: {
                                      value: _vm.rewards.points,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.rewards, "points", $$v)
                                      },
                                      expression: "rewards.points",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "v-row",
                                [
                                  _c(
                                    "v-col",
                                    { staticClass: "pb-0" },
                                    [
                                      _c("v-text-field", {
                                        attrs: {
                                          label: "User Points Before Deducted",
                                          dense: "",
                                          disabled: "",
                                          outlined: "",
                                        },
                                        model: {
                                          value: _vm.rewards.before_points,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.rewards,
                                              "before_points",
                                              $$v
                                            )
                                          },
                                          expression: "rewards.before_points",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-col",
                                    { staticClass: "pb-0" },
                                    [
                                      _c("v-text-field", {
                                        attrs: {
                                          label: "User Points After Deducted",
                                          dense: "",
                                          disabled: "",
                                          outlined: "",
                                        },
                                        model: {
                                          value: _vm.rewards.after_points,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.rewards,
                                              "after_points",
                                              $$v
                                            )
                                          },
                                          expression: "rewards.after_points",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                { staticClass: "d-flex justify-start mt-4" },
                                [
                                  _c("v-textarea", {
                                    attrs: {
                                      label: "Remark",
                                      outlined: "",
                                      dense: "",
                                    },
                                    model: {
                                      value: _vm.rewards.remark,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.rewards, "remark", $$v)
                                      },
                                      expression: "rewards.remark",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                { staticClass: "d-flex justify-end" },
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: { color: "primary", plain: "" },
                                      on: {
                                        click: function () {
                                          _vm.$router.go(-1)
                                        },
                                      },
                                    },
                                    [_vm._v(" Cancel ")]
                                  ),
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: {
                                        color: "primary",
                                        loading: _vm.api.isLoading,
                                      },
                                      on: { click: _vm.validateInput },
                                    },
                                    [_vm._v(" Update ")]
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                { staticClass: "d-flex justify-end my-2" },
                                [_c("AError", { attrs: { api: _vm.api } })],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }